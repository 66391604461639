import yaml from 'js-yaml'
import { YamlConfig } from './YamlConfig'
interface Settings {
  domain: string
  audience: string
  organizationId: string
  clientId: string
  accountsApiUrl: string
  workspaceApiUrl: string
  workflowsApiUrl: string
  notifierUrl: string
  apiBaseUrl: string
  accountsUrl: string
  mapsApiUrl: string
  mapsPublicUrl: string
  recurlyUrl: string
  publicUrl: string
  hubspotId: string
  statsApiUrl: string
  hubspotLimitFormId: string
  hubspotSubscriptionFormId: string
  allScopes: string
  doCatalogApiUrl: string
  googleMapsApiKey: string
  importApiUrl: string
  publicCatalogUrl: string
  bigqueryOAuthEnabled: boolean
  enableFeaturedApplications: boolean
  accessInSchema: string
  localStorageOrgIdKey: string
  studentClientId: string
  loginAsStudentKey: string
  launchDarklyClientSideId: string
  cartoVersion: string
  documentationUrl: string
  feedbackUrl: string
  supportEmail: string
}
const settings: Settings = {
  domain: '',
  audience: '',
  organizationId: '',
  clientId: '',
  accountsApiUrl: '',
  workspaceApiUrl: '',
  workflowsApiUrl: '',
  notifierUrl: '',
  apiBaseUrl: '',
  accountsUrl: '',
  statsApiUrl: '',
  publicUrl: process.env.PUBLIC_URL,
  publicCatalogUrl: '',
  mapsApiUrl: '',
  mapsPublicUrl: '',
  recurlyUrl: '',
  hubspotId: '',
  hubspotLimitFormId: '',
  hubspotSubscriptionFormId: '',
  doCatalogApiUrl: '',
  googleMapsApiKey: '',
  allScopes:
    'read:current_user update:current_user read:connections write:connections read:maps write:maps read:account admin:account',
  importApiUrl: '',
  bigqueryOAuthEnabled: false,
  enableFeaturedApplications: false,
  accessInSchema: 'carto',
  localStorageOrgIdKey: 'orgId',
  studentClientId: '',
  loginAsStudentKey: 'student_account',
  launchDarklyClientSideId: '',
  cartoVersion: '',
  documentationUrl: 'https://docs.carto.com',
  feedbackUrl: 'https://cartohq.typeform.com/to/a4YHHwPS',
  supportEmail: 'support@carto.com'
}

export const DEFAULT_ANALYTICS_TOOLBOX_PROJECT = 'carto-un'

const defaultSpatialExtensionProject = () => DEFAULT_ANALYTICS_TOOLBOX_PROJECT

export const ANALYTICS_TOOLBOX_PROJECT_BY_REGION = {
  // AUSTRALIA
  'australia-southeast1': `${defaultSpatialExtensionProject()}-au-se1`,
  'australia-southeast2': `${defaultSpatialExtensionProject()}-au-se2`,
  // EUROPE
  EU: `${defaultSpatialExtensionProject()}-eu`,
  'europe-west1': `${defaultSpatialExtensionProject()}-eu-we1`,
  'europe-west2': `${defaultSpatialExtensionProject()}-eu-we2`,
  'europe-west3': `${defaultSpatialExtensionProject()}-eu-we3`,
  'europe-west4': `${defaultSpatialExtensionProject()}-eu-we4`,
  'europe-west6': `${defaultSpatialExtensionProject()}-eu-we6`,
  'europe-north1': `${defaultSpatialExtensionProject()}-eu-no1`,
  'europe-central2': `${defaultSpatialExtensionProject()}-eu-ce2`,
  // ASIA
  'asia-northeast1': `${defaultSpatialExtensionProject()}-as-ne1`,
  'asia-northeast2': `${defaultSpatialExtensionProject()}-as-ne2`,
  'asia-northeast3': `${defaultSpatialExtensionProject()}-as-ne3`,
  'asia-southeast1': `${defaultSpatialExtensionProject()}-as-se1`,
  'asia-southeast2': `${defaultSpatialExtensionProject()}-as-se2`,
  'asia-south1': `${defaultSpatialExtensionProject()}-as-so1`,
  'asia-south2': `${defaultSpatialExtensionProject()}-as-so2`,
  'asia-east1': `${defaultSpatialExtensionProject()}-as-ea1`,
  'asia-east2': `${defaultSpatialExtensionProject()}-as-ea2`,
  // NORTH AMERICA
  'northamerica-northeast1': `${defaultSpatialExtensionProject()}-na-ne1`,
  'northamerica-northeast2': `${defaultSpatialExtensionProject()}-na-ne2`,
  // SOUTH AMERICA
  'southamerica-east1': `${defaultSpatialExtensionProject()}-sa-ea1`,
  'southamerica-west1': `${defaultSpatialExtensionProject()}-sa-we1`,
  // US
  'us-central1': `${defaultSpatialExtensionProject()}-us-ce1`,
  'us-west1': `${defaultSpatialExtensionProject()}-us-we1`,
  'us-west2': `${defaultSpatialExtensionProject()}-us-we2`,
  'us-west3': `${defaultSpatialExtensionProject()}-us-we3`,
  'us-west4': `${defaultSpatialExtensionProject()}-us-we4`,
  'us-east1': `${defaultSpatialExtensionProject()}-us-ea1`,
  'us-east4': `${defaultSpatialExtensionProject()}-us-ea4`
}

export async function loadConfig() {
  const configUrl = process.env.REACT_APP_CONFIG_URL || '/config.yaml'

  const config = await fetchConfigYaml(configUrl)

  applyConfig(config)
}

export async function applyConfig(config: YamlConfig) {
  const { auth0, apis, hubspot, launchDarkly, cartoVersion } = config

  // AUTH0
  settings.domain = auth0.domain
  settings.audience = auth0.audience
  settings.clientId = auth0.clientId
  settings.studentClientId = auth0.studentClientId
  settings.organizationId = auth0.organizationId

  // APIs
  settings.apiBaseUrl = apis.baseUrl
  settings.accountsApiUrl = apis.accountsUrl
  settings.workspaceApiUrl = apis.workspaceUrl
  settings.workflowsApiUrl = apis.workflowsUrl ? `${apis.workflowsUrl}/workflows` : `${apis.baseUrl}/v3/workflows`
  settings.notifierUrl = apis.notifierUrl
  settings.mapsApiUrl = `${apis.baseUrl}/v3/maps`
  settings.statsApiUrl = `${apis.baseUrl}/v3/stats`
  settings.doCatalogApiUrl = apis.doUrl
  settings.importApiUrl = `${apis.importUrl || apis.baseUrl}/v3/imports`
  settings.recurlyUrl = `${apis.recurlyUrl}`

  // Hubspot
  settings.hubspotId = hubspot.id
  settings.hubspotLimitFormId = hubspot.limitFormId
  settings.hubspotSubscriptionFormId = hubspot.requestSubscriptionFormId

  // LaunchDarkly
  settings.launchDarklyClientSideId = launchDarkly.clientSideId

  // CARTO Version
  const selfHosted = cartoVersion.selfHosted
  settings.cartoVersion = selfHosted ? `${selfHosted}` : 'SaaS'

  // common
  const accountsUrl = config.accountsUrl.endsWith('/')
    ? config.accountsUrl.substring(0, config.accountsUrl.length - 1)
    : config.accountsUrl // ensure we don't have a trailing slash
  settings.accountsUrl = accountsUrl
  settings.mapsPublicUrl = config.publicMapUrl
  settings.googleMapsApiKey = config.googleMapsApiKey
  settings.publicCatalogUrl = config.publicCatalogUrl
  settings.accessInSchema = config.accessInSchema

  // TODO: this flag should be managed in Backend (maybe in /me),
  // they already have a flag that we could use to decide if we show or hide OAuth option
  settings.bigqueryOAuthEnabled = config.bigqueryOAuthEnabled
  settings.enableFeaturedApplications = config.enableFeaturedApplications
}

export function parseYamlConfig(data: string): YamlConfig {
  try {
    return yaml.load(data) as YamlConfig
  } catch (e) {
    const msg = e instanceof Error ? e.message : ''
    throw new Error(`Error parsing config file. ${msg}`)
  }
}

export async function fetchConfigYaml(configUrl: string) {
  let yamlText
  try {
    const response = await fetch(configUrl)

    if (!response.ok) {
      throw new Error(`File status code ${response.status}.`)
    }
    yamlText = await response.text()
    if (!yamlText) {
      throw new Error(`Empty config file detected.`)
    }
  } catch (e) {
    const msg = e instanceof Error ? e.message : ''
    throw new Error(`Cannot download file ${configUrl}. ${msg}`)
  }

  return parseYamlConfig(yamlText)
}

export default settings
